import { Property } from "../../reactor/Types/Type"
import { ToolButton } from "./ToolButton"
import { createRef, CSSProperties, useEffect, useMemo, useState } from "react"
import { createEditor, BaseEditor, Range, Transforms, Editor, Element } from "slate"
import {
    Editable,
    RenderElementProps,
    RenderLeafProps,
    Slate,
    withReact,
    ReactEditor,
    useSlate,
} from "slate-react"
import { isKeyHotkey } from "is-hotkey"
import { HistoryEditor } from "slate-history"
import {
    RichTextElement,
    RichTextParentElement,
    RichTextSpan,
} from "../../packages/rich-text/RichText"
import { ColorStyles } from "../../packages/ui"
import { PropRow } from "./PropRow"
import { YTools } from "../../packages/y/YTools"
import { slateNodesToInsertDelta, withYHistory, withYjs, YjsEditor } from "@slate-yjs/core"
import React from "react"
import * as Y from "yjs"

declare module "slate" {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor & HistoryEditor
        Element: RichTextParentElement
        Text: RichTextSpan
    }
}

export function RichTextView({
    obj,
    property,
    label,
    buttons,
    isEmbedded,
}: {
    obj: YTools.Node
    property: Property
    label?: string
    buttons: ToolButton[]
    isEmbedded?: boolean
}) {
    return (
        <PropRow label={label} buttons={buttons} isEmbedded={isEmbedded}>
            <RichTextEditor
                obj={obj}
                property={property}
                className="form-control"
                style={{
                    width: "100%",
                    margin: isEmbedded ? 2 : undefined,
                    resize: "vertical",
                    overflow: "auto",
                }}
            />
        </PropRow>
    )
}

export function RichTextEditor({
    obj,
    property,
    className,
    style,
}: {
    obj: YTools.Node
    property: Property
    className?: string
    style: CSSProperties
}) {
    const value = YTools.get(obj, property.name)

    const editor = useMemo(() => withYHistory(withYjs(withReact(createEditor()), value)), [value])
    const editorRef = createRef<HTMLDivElement>()

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        const { selection } = editor

        // Default left/right behavior is unit:'character'.
        // This fails to distinguish between two cursor positions, such as
        // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
        // Here we modify the behavior to unit:'offset'.
        // This lets the user step into and out of the inline without stepping over characters.
        // You may wish to customize this further to only use unit:'offset' in specific cases.
        if (selection && Range.isCollapsed(selection)) {
            const { nativeEvent } = event
            if (isKeyHotkey("left", nativeEvent)) {
                event.preventDefault()
                Transforms.move(editor, { unit: "offset", reverse: true })
                return
            }
            if (isKeyHotkey("right", nativeEvent)) {
                event.preventDefault()
                Transforms.move(editor, { unit: "offset" })
                return
            }
        }
    }

    const [focused, setFocused] = useState(false)

    useEffect(() => {
        if (value) {
            YjsEditor.connect(editor)
            return () => {
                YjsEditor.disconnect(editor)
            }
        }
    }, [editor, value])

    return (
        <Slate editor={editor} initialValue={[]}>
            <div ref={editorRef} style={{ position: "relative" }}>
                {focused && (
                    <div
                        style={{
                            position: "absolute",
                            top: -42,
                            height: 48,
                            left: 150,
                            borderRadius: 2,
                            padding: 4,
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <MarkButton format="bold" icon="𝐁" />
                        <MarkButton format="italic" icon="𝐼" />
                        <MarkButton format="underline" icon="𝑈" />
                        <MarkButton format="code" icon="𝙲" />
                        <Spacer />
                        <BlockButton format="UnorderedList" icon="•" />
                        <BlockButton format="OrderedList" icon="1." />
                        <Spacer />
                        <BlockButton format="Heading1" icon="H1" />
                        <BlockButton format="Heading2" icon="H2" />
                        <Spacer />
                    </div>
                )}

                <Editable
                    className={className}
                    style={style}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={onKeyDown}
                    onFocus={() => {
                        if (value === undefined) {
                            const text = YTools.set(obj, property.name, new Y.XmlText())
                            text.applyDelta(
                                slateNodesToInsertDelta([
                                    {
                                        type: "Paragraph",
                                        children: [{ text: "" }],
                                    },
                                ])
                            )
                        }
                        setFocused(true)
                    }}
                    onBlur={() => {
                        setFocused(false)
                    }}
                />
            </div>
        </Slate>
    )
}

type Mark = keyof Omit<RichTextSpan, "text">

function Spacer() {
    return <div style={{ width: 16 }} />
}

const Button = ({
    active,
    icon,
    onClick,
}: {
    active: boolean
    icon: string
    onClick: () => void
}) => {
    return (
        <button
            style={{
                border: "1px solid " + ColorStyles.gray[100],
                padding: 2,
                borderRadius: 4,
                margin: 2,
                width: 28,
                height: 28,
                color: active ? ColorStyles.primary[900] : ColorStyles.primary[700],
                backgroundColor: active ? ColorStyles.primary[200] : undefined,
            }}
            onMouseDown={onClick}
        >
            {icon}
        </button>
    )
}

const MarkButton = ({ format, icon }: { format: Mark; icon: string }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            icon={icon}
            onClick={() => toggleMark(editor, format)}
        />
    )
}

const BlockButton = ({ format, icon }: { format: RichTextElement["type"]; icon: string }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(editor, format)}
            icon={icon}
            onClick={() => toggleBlock(editor, format)}
        />
    )
}

const isBlockActive = (editor: Editor, format: RichTextElement["type"]) => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
        })
    )

    return !!match
}

const listTypes = ["UnorderedList", "OrderedList"]

const toggleBlock = (editor: Editor, format: RichTextElement["type"]) => {
    const isActive = isBlockActive(editor, format)
    const isList = listTypes.includes(format)

    Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && listTypes.includes(n.type),
        split: true,
    })
    const newProperties: Partial<Element> = {
        type: (isActive ? "Paragraph" : isList ? "ListItem" : format) as any,
    }

    Transforms.setNodes<Element>(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const isMarkActive = (editor: Editor, format: Mark) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const toggleMark = (editor: Editor, format: Mark) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const renderElement = ({ attributes, children, element }: RenderElementProps) => {
    if (!("type" in element)) {
        // This should not happen, Slate should not consider this an element
        return <div {...attributes}>ERROR: {children}</div>
    }

    switch (element.type) {
        case "Paragraph":
            return <p {...attributes}>{children}</p>

        case "Heading1":
            return <h1 {...attributes}>{children}</h1>

        case "Heading2":
            return <h2 {...attributes}>{children}</h2>

        case "UnorderedList":
            return <ul {...attributes}>{children}</ul>

        case "OrderedList":
            return <ol {...attributes}>{children}</ol>

        case "ListItem":
            return <li {...attributes}>{children}</li>

        default:
            throw new Error("Unknown element type")
    }
}

const renderLeaf = ({ attributes, children, leaf }: RenderLeafProps) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

export type CursorData = {
    name: string
    color: string
}

export function addAlpha(hexColor: string, opacity: number): string {
    const normalized = Math.round(Math.min(Math.max(opacity, 0), 1) * 255)
    return hexColor + normalized.toString(16).toUpperCase()
}
