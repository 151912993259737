import { useReflectionReady } from "../../studio/reflection-client"
import { useIsEditing } from "./useIsEditing"
import { ObjectEditor } from "./ObjectEditor"
import { useFullHeight } from "../height-lock/HeightLockContext"
import { useSelectionContext } from "./SelectionContext"
import { useObserveYProxy } from "../y/useObserveYProxy"
import { createContext, useContext } from "react"

export function EditingPanel(props: { skipAncestors?: number }) {
    const editing = useIsEditing()
    const { selectedObject, selectedObjectType, selectedObjectPutType } = useSelectionContext()
    const reflection = useReflectionReady(editing)

    useObserveYProxy(selectedObject)

    useFullHeight()

    if (!reflection || !editing) return <></>

    return (
        <EditingPanelContext.Provider value={true}>
            <div
                style={{
                    fontSize: 12,
                    height: "100%",
                    borderRight: "1px solid #ddd",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    width: 350,
                    maxWidth: "40%",
                }}
            >
                {selectedObject && selectedObjectType && (
                    <ObjectEditor
                        obj={selectedObject}
                        type={selectedObjectType}
                        putType={selectedObjectPutType}
                        skipAncestors={props.skipAncestors}
                    />
                )}
            </div>
        </EditingPanelContext.Provider>
    )
}

export const EditingPanelContext = createContext(false)

/**
 * Returns true if the component is inside the editing panel (i.e. limited
 * horizontal space available).
 */
export function useIsInsideEditingPanel() {
    return useContext(EditingPanelContext)
}
